.App {
  text-align: center;
}

.App-header {
  background-color: #0032b0;
  min-height: 100vh;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@media (max-height: 767px) {
  .App-header {
    height: 768px;
  }
}

.App-link {
  color: #61dafb;
}

/* Semantic UI overrides */

.blurring.dimmable > .dimmer {
  background-color: rgba(4, 0, 255, 0.5) !important;
}
